.videoWrapper {
	position: relative;
	padding-bottom: 53.25%;
	padding-top: 25px;
	background-size: cover;
  height: 0;
  margin:0 auto 35px;
  @media(max-width:900px) { margin-bottom:30px; }
  @media(max-width:600px) { margin-bottom:25px; }
  @media(max-width:450px) { margin-bottom:20px; }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
