.home_links {
  padding: 55px 0 25px;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    li {
      width: calc(20% - 30px);
      background: #ffffff;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24);
      margin: 0 15px 30px;
      text-align: center;
      @media (max-width: 900px) {
        width: calc(33% - 30px);
      }
      @media (max-width: 600px) {
        width: calc(50% - 30px);
      }
      @media (max-width: 350px) {
        width: calc(100% - 30px);
      }
      a {
        display: block;
        color: #232323;
        padding: 20px;
        img {
          margin: 25px auto;
          display: block;
        }
        span {
          display: block;
          &.ico-wrap {
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            span {
              display: block;
              position: relative;
              z-index: 350;
              &:before {
                display: inline-block;
                content: " ";
                position: absolute;
                z-index: -1;
                width: 0;
                height: 0;
                border-style: solid;
              }
            }
            img {
              position: relative;
            }
          }
          &.title {
            font-size: 12px;
            color: #232323;
            text-transform: uppercase;
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: $title-font-family;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
      &:nth-child(4n + 1) {
        span {
          &.ico-wrap {
            span {
              &:before {
                left: -10px;
                top: 20px;
                border-width: 35px 35px 0 0;
                border-color: #d5e4ef transparent transparent transparent;
              }
            }
          }
        }
      }
      &:nth-child(4n + 2) {
        span {
          &.ico-wrap {
            span {
              &:before {
                right: -10px;
                top: 20px;
                border-width: 0 35px 35px 0;
                border-color: transparent #d5e4ef transparent transparent;
              }
            }
          }
        }
      }
      &:nth-child(4n + 3) {
        span {
          &.ico-wrap {
            span {
              &:before {
                right: -10px;
                bottom: 20px;
                border-width: 0 0 35px 35px;
                border-color: transparent transparent #d5e4ef transparent;
              }
            }
          }
        }
      }
      &:nth-child(4n + 4) {
        span {
          &.ico-wrap {
            span {
              &:before {
                left: -10px;
                bottom: 20px;
                border-width: 35px 0 0 35px;
                border-color: transparent transparent transparent #d5e4ef;
              }
            }
          }
        }
      }
    }
  }
}
.home-wrap {
  p {
    font-size: 16px;
    color: #171717;
    line-height: 1.5;
  }
}

.homecategories {
  &.margin {
    margin: 5% 0;
  }
  h1 {
    width: 49%;
    float: left;
    @media (max-width: 505px) {
      width: 100%;
      margin-bottom: 3px;
    }
  }
}

.description {
  &.homeimage {
    .textarea {
      @media (max-width: 790px) {
        order: 2;
      }
    }
    .imagewrap {
      @media (max-width: 790px) {
        width: 100%;
        order: 1;
        overflow: hidden;
        height: 0;
        padding-top: 50%;
        background-position: center;
      }
    }
  }
}
