button {
  border: 0;
}

.btn {
  background: $darkred;
  padding: 6px 17px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  &:after {
    content: " ";
    background-image: url("/assets/img/arrow_white.svg");
    background-repeat: no-repeat;
    width: 12px;
    height: 14px;
    margin-left: 10px;
    color: #fff;
    display: inline-block;
    position: relative;
    top: 2px;
  }

  &:hover {
    color: #fff;
  }
}

.btn_red {
  background: $lightred;
  padding: 6px 17px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;

  &:after {
    content: " ";
    background-image: url("/assets/img/arrow_white.svg");
    background-repeat: no-repeat;
    display: block;
    width: 7px;
    height: 11px;
    margin-left: 10px;
    color: #fff;
    display: inline-block;
  }

  &:hover {
    color: #fff;
  }
}

.back-btn {
  display: inline-block;
}

.moreinfobtn {
  font-size: 10px;
  text-decoration: underline;
  position: absolute;
  color: $darkred;
  font-size: 14px;
  font-weight: 600;
  float: left;
  margin-top: 10px;

  &:before {
    content: " ";
    background-image: url("/assets/img/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    background-color: #ffda3c;
    border-radius: 20px;
    background-size: 7px;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: #fff;
    display: inline-block;
  }

  &:active {}
}

.downloadsbtn {
  font-size: 10px;
  background-color: #1c4973;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  float: left;
  border-radius: 30px;
  padding: 8px 28px 8px 8px;
  margin-top: 10px;

  &:before {
    content: " ";
    background-image: url("/assets/img/icon-doc.svg");
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    background-color: #ffda3c;
    border-radius: 20px;
    background-size: 15px;
    display: block;
    width: 34px;
    height: 34px;
    margin-right: 20px;
    color: #fff;
    display: inline-block;
  }

  span {
    padding-top: 4px;
    display: inline-block;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}