$btn-height: 35px;
$btn-width: 35px;
$btn-color: #1C4973;
$border: 0;
$gallery-box-shadow: 0 0 3px 0 rgba(0,0,0,0.24);
$active-slide:#eee;

.product-img-gallery {
  width:100%;
  margin:0 auto;
  .product-img-header {
    margin:20px 0;
  }
  .product-img-middle {
    position: relative;
    display:flex;
    align-items:center;
    .product-img-body {
      position: relative;
      left:3px;
      width:calc(100% - 6px);
      box-shadow:$gallery-box-shadow;
      border:$border;
      padding-top: 65.8%;
      height: 0 !important;
      div {
        display:inline-block;
        vertical-align: top;
        width:100%;
        height:100%;
        position: relative;
        a {
          display: block;
          background-size:cover;
          background-position: center;
          background-repeat: no-repeat;
          height:100%;
        }
        img {
          opacity:1;
          width: 100%;
        }
        &.video{
          background-size: cover;
          background-position: center;
          img{
            opacity:0;
          }
          .playbtn{
            position: absolute;
            top: 38%;
            left: 41%;
            width: 20%;
            z-index: 100;
            opacity: 1;
          }
        }
      }
    }
    .cycle-btn {
      position: absolute;
      top:calc(50% - #{$btn-height / 2});
      width:$btn-width;
      height:$btn-height;
      text-align:center;
      display:inline-block;
      background:$btn-color;
      display:flex;
      justify-content:center;
      align-items:center;
      cursor:pointer;
      z-index: 100;
      &:active {
        top:calc(50% - #{$btn-height / 2 - 1});
      }
      &:hover {

      }
      span {
        display: block;
      }
      i {
        font-size:29px;
        color:#fff;
        line-height:40px;
      }
      &.cycle-prev {
        left:calc(0% - #{$btn-width / 2});
        @media(max-width:600px) {
          left:0;
        }
      }
      &.cycle-next {
        right:calc(0% - #{$btn-width / 2});
        @media(max-width:600px) {
          right:0;
        }
      }
      &.disabled {
        opacity:0.5;
        cursor: default;
        &:active {
          top:calc(50% - #{$btn-height / 2});
        }
      }
    }
  }
  .product-img-thumbs-wrapper {
    position: relative;
    margin-top:20px;
    margin-left:3px;
    width: calc(100% - 6px);
    @media(max-width:300px) {
      display:none;
    }
    .product-img-thumbs {
      margin:0;
      padding-bottom:3px;
      .thumb-item {
        display: inline-block;
        vertical-align: top;
        margin-top:1px;
        margin-bottom:10px;
        margin-right: 20px;
        position: relative;

        height:100%;
        cursor: pointer;
        .thumb-item-frame {
          position: relative;
          height:100%;
          width:100%;
          top:0;
          left:0;
          background-size:contain;
          background-position: center;
          background-repeat: no-repeat;
          border:$border;
          box-shadow:$gallery-box-shadow;
          img {
            display:block;
            opacity:1;
          }
        }
        &.cycle-slide-active {
          .thumb-item-frame {
            border-color:$active-slide;
          }
        }

        &.video{
          .thumb-item-frame {
            background-size: 130%;
            background-position: center;
            height: 0;
            padding-top: 67%;
            img{
              opacity: 0;
            }
            .playbtn{
              position: absolute;
              top: 29%;
              left: 36%;
              width: 30%;
              z-index: 100;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.cover {
    .product-img-middle {
      .product-img-body {
        div {
          a { background-size:cover;  }
        }
      }
    }
  }
  &.contain {
    .product-img-middle {
      .product-img-body {
        div {
          a { background-size:contain;  }
        }
      }
    }
  }
}
