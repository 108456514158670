.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .top {
    margin: 0;
    width: 100%;
    background: #ffffff;
    padding: 16px 0;

    .inner-wrap {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .col {
        padding: 0;
        width: auto;
        align-items: center;
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        a {
          img {
            @media (max-width: 600px) {
              max-width: 200px;
            }
          }
        }

        &:nth-child(2) {
          justify-content: flex-end;
          flex-grow: 2;

          @media (max-width: 845px) {
            margin-right: 10%;
          }
        }

        .contactinfo {
          display: flex;

          @media (max-width: 1062px) {
            display: none;
          }

          h5 {
            color: $darkred;
            font-size: 14px;
            font-weight: 700;
            margin-left: 20px;
            margin-bottom: 0;

            a {
              color: $darkgrey;

              @media (max-width: 1142px) {
                width: 100%;
                display: block;
              }
            }
          }
        }

        .socialmedia {
          margin-left: 40px;

          @media (max-width: 845px) {
            display: none;
          }

          a[href="#"],
          a[href=""] {
            display: none;
          }
        }
      }
    }
  }

  .middle {
    margin: 0;
    width: 100%;
    background: $darkred;
    height: 58px;
    display: flex;

    @media (max-width: 900px) {
      display: none;
    }

    .inner-wrap {
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;

      ul {
        display: inline-block;
        margin: 0;

        li {
          border-left: 1px solid #ffffff;
          display: inline-block;
          text-transform: uppercase;
          font-family: $title-font-family;
          font-size: 12px;
          color: $grey;
          padding: 0 18px;
          letter-spacing: 1.42px;
          position: relative;

          &:nth-child(1) {
            border-left: 0;
            ;
            padding: 0 18px 0 0;
          }

          &:nth-child(2) {
            @media (min-width: 1330px) {
              border-left: 0;
              padding: 0 18px 0 0;
            }
          }

          &:last-child {
            padding: 0 0 0 18px;
          }

          a {
            color: #ffffff;
            font-weight: 600;
            letter-spacing: 0;

            &.active {
              border-bottom: 3px $yellow;
            }
          }

          &.subnav {
            display: none;

            @media (max-width: 1330px) {
              display: inline-block;
            }

            &:hover {
              text-decoration: underline;
              width: fit-content;
              margin-bottom: -5px;
            }

            .subitems {
              display: none;
            }

            &:hover {
              .subitems {
                border-top: 10px solid $darkred;
                display: inline-block;
                width: 180px;
                padding: 10px 0;
                top: 20px;
                left: 0;
              }
            }
          }

          .subitems {
            background-color: #ececec;
            z-index: 9999;
            display: inline-block;
            position: absolute;

            li {
              border-top: none;
              padding: 5px 20px;

              a {
                color: #5f5f5f;
                text-transform: uppercase;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    margin: 0;
    background: #f6f6f6;
    height: 58px;
    width: 100%;

    @media (max-width: 1120px) {
      height: 70px;
    }

    @media (max-width: 700px) {
      height: 10px;
      background: $darkred;
    }

    .highlights {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 18px 0px;
      margin: 0;

      @media (max-width: 700px) {
        display: none;
      }

      li {
        display: flex;
        align-items: center;
        padding: 0 20px;

        @media (max-width: 818px) {
          padding: 0 10px 0 0;
        }

        img {}

        h6 {
          margin-left: 16px;
          font-size: 13px;
          font-weight: 600;
          font-family: $title-font-family;
          color: #5a5a5a;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1330px) {
  .navbar .middle .inner-wrap ul li.dontshow {
    display: none;
  }

  .navbar .middle .inner-wrap ul li.dropdown-menu {
    display: inline-block;
  }
}

@media (min-width: 1330px) {
  .navbar .middle .inner-wrap ul li.dontshow {
    display: inline-block;
  }

  .navbar .middle .inner-wrap ul li.dropdown-menu {
    display: none;
  }
}

/* Mobile Button */
#mobile-burger-btn {
  cursor: pointer;
  display: none;
  height: 38px;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  background: #404040;
  border-radius: 4px;
  color: white;

  i {
    font-size: 28px;
    position: relative;
    top: 5px;

    &:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 845px) {
    display: inline-block;
    position: absolute;
    right: 5%;
    top: 10px;
    z-index: 9999;
  }

  &.menu-open {

    //right:calc(15.625em + 20px);
    i {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}

/* Mobile Button */
#product-burger-btn {
  cursor: pointer;
  display: inline-block;
  height: 38px;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  border-radius: 2px;
  color: white;
  margin-right: 15px;

  i {
    font-size: 28px;
    position: relative;
    top: 5px;

    &:nth-child(2) {
      display: none;
    }
  }

  &.menu-open {

    //right:calc(15.625em + 20px);
    i {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}

//dropdown nav