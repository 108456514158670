.create-an-account {
  background: #ffffff;
  padding: 0;
  .inner-wrap {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    h1 {
      margin: 30px 0 50px 0;
      @media (max-width: 630px) {
        width: 90%;
        margin: 0 5% 15px 5%;
        text-align: center;
      }
    }
  }
}

.formarea {
  background: #fff;
  width: 100%;
  padding: 30px;
  h2 {
    font-family: $standard-font-family;
    font-size: 23px;
    color: $darkred;
    text-transform: capitalize;
  }
  h3 {
    font-family: $standard-font-family;
    font-size: 14px;
    font-weight: 800;
    color: $darkred;
    display: block;
    margin-bottom: 20px;
  }
  p {
    font-size: 13px;
    color: #626262;
  }
  .bold {
    font-size: 13px;
    color: #626262;
    font-weight: 600;
    margin-bottom: 30px;
  }
  label {
    font-family: $standard-font-family;
    font-size: 14px;
    font-weight: 800;
    color: $darkred;
    text-transform: capitalize;
    display: block;
  }
  hr {
    margin-bottom: 50px;
    margin-top: 30px;
    border-color: #27639b;
    &.top {
      margin-top: 50px;
    }
  }
  .form-item-half {
    width: 48.5%;
    margin-bottom: 30px;
    float: left;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
    @media (max-width: 630px) {
      width: 100%;
    }
  }
  .form-item-full {
    width: 100%;
    margin-bottom: 30px;
    float: left;
    input {
      width: 48.5%;
      margin-bottom: 15px;
      float: left;
    }
    input:nth-child(odd) {
      float: right;
    }
    @media (max-width: 630px) {
      width: 100%;
      input {
        width: 100%;
        margin-bottom: 15px;
        float: left;
      }
    }
  }
  .form-item-radio {
    margin-bottom: 30px;
    label {
      margin-bottom: 20px;
    }
    .radiobtn {
      margin-right: 30px;
      float: left;
      display: inline;
      input {
        float: left;
        width: 26px;
      }
      h5 {
        float: left;
        padding: 4px 0;
      }
      @media (max-width: 630px) {
        width: 100%;
      }
    }
  }
  input {
    &:required {
      border-right: 3px solid #fdb4b4;
    }
  }
}

.btn_submit {
  background: $yellow;
  color: #212529;
  border-radius: 4px;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  font-family: $title-font-family;
  margin-top: 30px;
  padding: 16px 0;
  @media (max-width: 630px) {
    width: 90%;
    margin: 30px 5%;
  }
}
