table {
  width: 100%;
  border: 1px solid #e8e8e8;

  thead {
    tr {
      th {
        background: #013040;
        color: #fff;
        padding: 12px 16px;
      }
    }
  }
  tbody {
    tr {
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      &:nth-child(odd) {
        background: #ffffff;
      }
      td {
        padding: 12px 16px;
        text-align: left;
        font-size: 12px;
        &:nth-child(1) {
          text-align: left;
          font-weight: 600;
          border-right: 1px solid #e8e8e8;
        }
      }
    }
  }
}
