.abouttopvideo {
  width: 100%;
  background: $darkred;

  video {
    width: 100%;
  }

  .inner-wrap {
    max-width: 1000px;
    padding: 50px 0;
    text-align: center;

    h1 {
      font-size: 40px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 70px;
    }
  }
}

.abouttop {
  background: #27639B;

  .inner-wrap {
    max-width: 1000px;
    padding: 50px 0;
    text-align: center;

    .videoWrapper {
      max-width: 860px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.27);
      margin-bottom: 50px;
    }

    h1 {
      font-size: 40px;
      color: #fff;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 70px;
      color: #fff;
    }
  }
}

.aboutdetail {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 0 50px 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;

  h1 {
    font-size: 40px;
    color: $darkred;
  }
}

.aboutpage {
  background: #f6f6f6;

  h2 {
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
  }
}