h1,
h2 {
  font-family: $title-font-family;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

h1 {
  letter-spacing: 0;
  font-style: normal;
  font-size: 27px;
  line-height: 1.2;
  text-transform: uppercase;
  color: $darkred;
}

h2 {
  font-size: 42px;
  line-height: 1.2;
  text-transform: uppercase;
  color: $darkred;
}

p {
  margin-bottom: 2rem;
}

.redsub {
  color: $darkred;
  font-weight: bold;

  @media (max-width: 800px) {
    color: white
  }
}

.darkgrey {
  font-size: 27px;
  font-weight: 700;
  color: #3b3b3b;
  text-transform: uppercase;
}