.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-bottom: 5% !important;

  li {
    width: 24% !important;
    margin-bottom: 1.33%;
    margin-right: 1.33%;
    height: 110px;
    background: $darkred;
    transition: background-color 0.5s ease;

    &:nth-child(4n) {
      margin-right: 0;
    }

    @media (max-width: 1280px) {
      width: 32.333% !important;

      &:nth-child(4n) {
        margin-right: 1.33%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (max-width: 945px) {
      width: 49% !important;

      &:nth-child(4n) {
        margin-right: 1.33%;
      }

      &:nth-child(3n) {
        margin-right: 1.33%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 628px) {
      width: 100% !important;
      margin-bottom: 2%;
      margin-right: 0;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .categorylink {
      display: flex;
      flex-wrap: nowrap;

      .imgwrap {
        width: 40%;
        display: block;
        max-width: 110px;
        overflow: hidden;

        img {
          width: 100%;
          transition: 0.3s ease-in-out;
          transform: rotate(0) scale(1);
        }
      }

      .textwrap {
        width: 60%;
        padding: 20px 20px;
        color: #fff;
        font-family: $title-font-family;

        h4 {
          font-size: 15px;
          font-weight: 600;
        }

        span {
          font-size: 12px;
          text-decoration: underline;
          position: absolute;
          bottom: 20px;
          float: left;

          &:before {
            content: " ";
            background-image: url("/assets/img/arrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            float: left;
            background-color: #ffda3c;
            border-radius: 10px;
            background-size: 5px;
            display: block;
            width: 17px;
            height: 17px;
            margin-right: 10px;
            color: #fff;

            display: inline-block;
          }
        }
      }

      &:hover {
        text-decoration: none;
      }

      &:active {
        top: 0;
      }
    }

    &:hover {
      background: $lightred;

      .imgwrap {
        img {
          transform: rotate(5deg) scale(1.2);
        }
      }
    }
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-bottom: 5%;

  li {
    width: 24%;
    margin-bottom: 1.33%;
    margin-right: 1.33%;
    background: #e9eff4;

    &:nth-child(4n) {
      margin-right: 0;
    }

    @media (max-width: 900px) {
      width: 32%;
      margin-bottom: 2%;
      margin-right: 2%;

      &:nth-child(4n) {
        margin-right: 2%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (max-width: 600px) {
      width: 48%;
      margin-bottom: 3%;
      margin-right: 3%;

      &:nth-child(4n) {
        margin-right: 3%;
      }

      &:nth-child(3n) {
        margin-right: 3%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 320px) {
      width: 100%;
      margin-bottom: 2%;
      margin-right: 0;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .productlink {
      display: flex;
      flex-direction: column;

      .imgwrap {
        width: 100%;
        display: block;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .textwrap {
        width: 100%;
        padding: 20px 20px;
        background: #e9eff4;
        font-family: $title-font-family;
        color: $darkred;
        text-align: center;

        @media (max-width: 1000px) {
          padding: 10px;
        }

        h4 {
          font-size: 20px;
          font-weight: 700;

          @media (max-width: 1000px) {
            font-size: 16px;
          }
        }

        h5 {
          font-size: 14px;
          font-weight: 700;
          color: $lightred;

          @media (max-width: 1000px) {
            font-size: 12px;
          }
        }
      }

      &:hover {
        text-decoration: none;
      }

      &:active {
        top: 0;
      }
    }
  }

  &.videogallery {
    border-bottom: none;

    .textwrap {
      h4 {
        font-size: 16px !important;
      }
    }

    .img-wrap {
      background-size: cover;
      background-position: center;
      height: 0;
      padding-top: 67%;

      img {
        opacity: 0;
      }

      .playbtn {
        position: absolute;
        top: 29%;
        left: 41%;
        width: 20%;
        z-index: 100;
        opacity: 1;
      }
    }
  }
}

.galleryarea {
  margin: 5% 0;
}