.contact_top {
  .inner-wrap {
    max-width: 800px;
    padding-top: 50px;

    form button.btn {
      display: inline-block;
      border-radius: 4px !important;
      padding: 20px 20px;
    }
  }
}

.contact_bottom {
  margin-top: 100px;

  .map_left {
    width: 50%;
    float: left;
    height: 520px;

    @media (max-width: 740px) {
      width: 100%;
      height: 300px;
    }
  }

  .contact_details {
    background: $darkred;
    background-image: url("/assets/img/bck_footer_red.png");
    background-repeat: no-repeat;
    background-position: 75% 100%;
    position: relative;
    width: 50%;
    float: right;
    padding: 8% 5%;
    height: 520px;
    font-weight: 400;

    @media (max-width: 740px) {
      width: 100%;
      height: auto;
      min-height: 350px;
    }

    .txt-wrap {
      text-align: left;
      margin: 0 auto;
      margin-left: 28%;

      @media (max-width: 740px) {
        margin-left: 0%;
      }

      h2 {
        color: #fff;
      }

      p {
        color: #fff;
      }

      img {
        margin-bottom: 30px;
        width: 60%;
      }

      .contact_address {
        margin-bottom: 40px;
      }

      .btn_white {
        background: #ffffff;
        padding: 12px 21px;
        border-radius: 4px;
        color: $darkred;
        font-size: 14px;

        &:after {
          content: " ";
          background-image: url("/assets/img/arrow_red.svg");
          background-repeat: no-repeat;
          display: block;
          width: 9px;
          height: 15px;
          margin-left: 10px;
          position: relative;
          top: 4px;
          color: #fff;
          display: inline-block;
        }
      }
    }
  }
}

.subpage-main .subpage-cols {
  @media (max-width: 600px) {
    flex-direction: column;
  }

  div.col.contact-frm {
    flex-basis: auto;
    width: calc(55% - 30px);
    margin-right: 30px;

    button {
      width: auto;
    }

    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 35px;
      margin-right: 0;
    }
  }

  div.col.contact-details {
    padding: 35px;
    background: #eff0f1;
    width: 45%;
    font-size: 17px;
    color: #1e1e1e;
    line-height: 1.3;
    flex-basis: auto;
    border-bottom: 50px white solid;

    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (max-width: 450px) {
      font-size: 15px;
    }

    h2 {
      font-size: 32px;
      color: #223b5e;
      margin-bottom: 20px;

      @media (max-width: 900px) {
        font-size: 29px;
      }

      @media (max-width: 600px) {
        font-size: 26px;
      }

      @media (max-width: 450px) {
        font-size: 24px;
      }
    }

    strong {
      display: block;
      margin-bottom: 3px;
    }

    p {
      margin-bottom: 25px;

      a {
        color: #1e1e1e;
        text-decoration: underline;
      }
    }

    @media (max-width: 900px) {
      padding: 30px;
    }

    @media (max-width: 600px) {
      padding: 25px;
      width: 100%;
      border-bottom: 0;
    }

    @media (max-width: 450px) {
      padding: 20px;
    }
  }
}

.contact-locations {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  border-top: 1px #c8c8c8 solid;
  margin-top: 55px;
  padding-top: 55px;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    padding-top: 35px;
    margin-top: 35px;
  }

  .col {
    width: calc(33.3333 - 26.666px);
    background: #ffffff;
    border: 1px solid #dadada;
    padding-left: 0;
    padding-right: 0;

    &:nth-child(2) {
      margin-left: 40px;
      margin-right: 40px;
    }

    @media (max-width: 900px) {
      width: 100%;
      flex-basis: auto;
      display: flex;
      align-items: stretch;

      &:nth-child(2) {
        margin: 20px 0;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .img-wrap {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 213px;
      overflow: hidden;
      width: 100%;

      img {
        opacity: 0;
      }

      @media (max-width: 900px) {
        width: 300px;
        height: 100%;
        flex-basis: auto;
        padding: 30px;

        img {
          height: 100%;
        }
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    .txt-wrap {
      padding: 30px;

      color: #2c2c2c;
      line-height: 1.5;

      @media (max-width: 900px) {
        width: calc(100% - 300px);
      }

      @media (max-width: 600px) {
        width: 100%;
        text-align: center;
      }

      p {
        font-size: 14px;
      }

      h3 {
        font-size: 22px;
        color: #1f3b5e;
        font-family: $title-font-family;
        font-style: normal;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }
  }
}

.red-link {
  color: $darkred;
}