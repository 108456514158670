/* --------------------------------

Primary style

-------------------------------- */
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


/* --------------------------------

Patterns - reusable parts of our design

-------------------------------- */
@media only screen and (min-width: 900px) {
  .cd-is-hidden {
    visibility: hidden;
  }
}

/* --------------------------------

Vertical Timeline - by CodyHouse.co

-------------------------------- */


.cd-timeline {
  overflow: hidden;

}

.cd-timeline__container {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 2em 0;
}

.cd-timeline__container::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #dddddd;
}

@media only screen and (min-width: 900px) {
  .cd-timeline {
    padding-top: 3em;
    padding-bottom: 3em;
    margin: 0;
  }

  .cd-timeline__container::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline__block {
  position: relative;
  margin: 2em 0;
}

.cd-timeline__block:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.cd-timeline__block:first-child {
  margin-top: 0;
}

.cd-timeline__block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  .cd-timeline__block {
    margin: 4em 0;
  }
}

.cd-timeline__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: $darkred;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.cd-timeline__img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

.cd-timeline__img.cd-timeline__img--picture {
  background: #75ce66;
}

.cd-timeline__img.cd-timeline__img--movie {
  background: #c03b44;
}

.cd-timeline__img.cd-timeline__img--location {
  background: #f0ca45;
}

@media only screen and (min-width: 900px) {
  .cd-timeline__img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .cd-timeline__img.cd-timeline__img--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline__content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
  -webkit-box-shadow: 0 3px 0 #d7e4ed;
  box-shadow: 0 3px 0 #d6d6d6;
}

.cd-timeline__content:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.cd-timeline__content::before {
  /* triangle next to content block */
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

.cd-timeline__content h2 {
  color: #303e49;
}

.cd-timeline__content p,
.cd-timeline__read-more,
.cd-timeline__date {
  font-size: 1.3rem;
}

.cd-timeline__content p {
  margin: 1em 0;
  line-height: 1.6;
}

.cd-timeline__read-more,
.cd-timeline__date {
  display: inline-block;
}

.cd-timeline__read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}

.cd-timeline__read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline__date {
  float: left;
  padding: .8em 0;
  opacity: .7;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .cd-timeline__content h2 {
    font-size: 2rem;
  }

  .cd-timeline__content p {
    font-size: 1.6rem;
  }

  .cd-timeline__read-more,
  .cd-timeline__date {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 900px) {
  .cd-timeline__content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .cd-timeline__content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }

  .cd-timeline__read-more {
    float: left;
  }

  .cd-timeline__date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 1.6rem;
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__content {
    float: right;
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__read-more {
    float: right;
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__date {
    left: auto;
    right: 122%;
    text-align: right;
  }

  .cd-timeline__content.cd-timeline__content--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 900px) {

  /* inverse bounce effect on even content blocks */
  .cd-timeline__block:nth-child(even) .cd-timeline__content.cd-timeline__content--bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}


/* demo style */
.cd-nugget-info {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  top: 0;
  left: 0;
}

.cd-nugget-info a {
  position: relative;
  font-size: 14px;
  color: #718ca1;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.cd-nugget-info a:hover {
  opacity: .8;
}

.cd-nugget-info span {
  vertical-align: middle;
  display: inline-block;
}

.cd-nugget-info span svg {
  display: block;
}

.cd-nugget-info .cd-nugget-info-arrow {
  fill: #718ca1;
}