html {
  box-sizing: border-box;
}

body {
  font-family: $standard-font-family;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}
p {
  font-family: $standard-font-family;
  margin-bottom: 2rem;
}

strong {
  font-weight: 700;
}

hr {
  border-top: 2px solid #d8d8d8;
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.page-wrap {
  position: relative;

  overflow: hidden;
  .page-inner-wrap {
    margin: 0 auto;
    position: relative;
    background: #ffffff;
    right: 0;
    z-index: 100;
    transition: right 0.3s ease;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    a {
      position: relative;
      &:active {
        top: 1px;
      }
    }
    &.push {
      right: 354px;
    }
  }
}

.subpage-main {
  background: #fff;
  padding: 75px 0;
  @media (max-width: 900px) {
    padding: 65px 0;
  }
  @media (max-width: 600px) {
    padding: 50px 0;
  }
  @media (max-width: 450px) {
    padding: 35px 0;
  }
  .subpage-cols {
    display: flex;
    .col {
      width: 50%;
      padding: 0;
    }
  }
}

.imagegrid {
  display: flex;
  flex-wrap: nowrap;
  margin: 5% 0;
  .imagegrid-cols {
    display: flex;
    width: calc(100% - 0px);
    .col {
      width: 50%;
      padding: 0;
      margin: 0 10px;
      img {
        width: 100%;
      }
    }
  }
}
