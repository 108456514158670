.help-and-advice-mid{
  padding: 40px 0;
  h1{
    margin-bottom: 40px;
  }
}
.help-and-advice-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-bottom: 5%;
  li{
    width: 32%;
    margin-bottom: 2%;
    margin-right: 2%;
    &:nth-child(3n){
      margin-right: 0;
    }
    @media(max-width:800px) {
      width: 48%;
      margin-bottom: 3%;
      margin-right: 3%;
      &:nth-child(4n){
        margin-right: 3%;
      }
      &:nth-child(3n){
        margin-right: 3%;
      }
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    @media(max-width:550px) {
      width: 100%;
      margin-bottom: 8%;
      margin-right: 0;
      &:nth-child(4n){
        margin-right: 0;
      }
      &:nth-child(3n){
        margin-right: 0;
      }
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    .item{
      overflow: hidden;
      float: left;
      .imagewrap{
        width: 100%;
        display: block;
        overflow: hidden;
        img{
          width: 100%
        }
      }
      .txt-wrap{
        width: 100%;
        padding: 60% 9% 9% 9%;
        display: inline-table;
        position: absolute;
        bottom: 0;
        font-family: $title-font-family;
        color: #ffffff;
        text-align: left;
        background: linear-gradient(0deg, rgba(13,35,56,1) 0%, rgba(13,35,56,0) 100%);
        transition: all .3s ease-out;
        h3{
          font-size: 24px;
          font-weight: 700;

        }
        h4{
          font-size: 14px;
          font-weight: 700;
          color: $yellow;

        }
        .readmore{
         text-decoration: underline;
         font-size: 12px;
        }
      }
    }
    &:hover{
      .txt-wrap{
        padding: 60% 9% 12% 9%;
      }
    }
  }
}

.help-and-advice-post{
  background:#F6F7FA;
  .inner-wrap{
    padding: 80px 0 40px 0;
  }
  .blog-left {
    width: 60%;
    float: left;
    @media(max-width:1140px) {
      width: 64%;
    }
    @media(max-width:980px) {
      width: 100%;
    }
    .top{
      h1{
        letter-spacing: 0;
        font-style: normal;
        font-size: 47px;
        line-height: 45px;
        text-transform: uppercase;
        color: $darkred;
        margin-bottom: 20px;
        font-weight: 800;
        margin-top: 7px;
      }
      h4{
        letter-spacing: 0;
        font-style: normal;
        font-size: 16px;
        line-height: 1.2;
        color: #333;
        font-weight: 700;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: $title-font-family;;
      }
    }
    .blog-content{
      p{
        font-size: 16px;
        line-height: 27px;
        margin-bottom: 28px;
        b{
          font-weight: 600;
        }
      }
      img{
        width: 100%;
        margin-bottom: 40px;

      }
      h2{
        margin-bottom: 20px;
        font-size: 28px;
        margin-top: 10px;
      }
      h3{
        margin-bottom: 20px;
        margin-top: 40px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        font-family: $title-font-family;
        color: $darkred;
      }

    }
    .video-container{
      width: 100%;
      iframe{
        width: 100%;
      }
    }


  }
  .blog-right {
    width: 30%;
    float: right;
    @media(max-width:1140px) {
      width: 33%;
    }
    @media(max-width:980px) {
      display: none;
    }
    h3{
      color: #fff;
      font-weight: 800;
      font-size: 23px;

      font-family: $title-font-family;
      text-transform: uppercase;
    }
    .blogcontact{
      float: right;
      text-align: center;
      text-transform: uppercase;
      .title{
        background: #27639B;
        border-radius: 4px 4px 0 0;
        margin: 0;
        padding: 20px 30px;
        h3{
          padding: 0;
          margin: 0;
        }
      }
      .shape{
        background: #2C70B0;
        float: left;
        width: 100%;
        .ribbon{
          width:100%;
        }
        .inner{
          padding: 50px 30px 30px 30px;
          h4{
            font-size: 27px;
            font-weight: 800;
            color: $yellow;
            width: 100%;
            margin-top: 10px;
            font-family: $title-font-family;
          }
          h5{
            font-size: 15px;
            font-weight: 800;
            color: #fff;
            width: 100%;
            font-family: $title-font-family;
          }
          .icon {
            border-radius: 60%;
            background: #1C4973;
            width: 84px;
            height: 84px;
            display: flex;
            padding: 20px;
            margin: 0 auto;
            justify-content: center;
            img{
              width: 100%;
            }
          }
        }
        &.light{
          background: #2F77BB;
        }
        &.nobackground{
          background: none;
        }
      }
    }
  }
}

.help-and-advice-more{
  padding: 40px 0;
  h2{
    margin-bottom: 40px;
    font-size: 26px;
  }
  .help-and-advice-list{
    margin-bottom: 0;
  }
}
