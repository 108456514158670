footer {
  position: relative;
  .middle {
    background: $grey;
    width: 100%;
    background-image: url("/assets/img/bck_footer.png");
    background-repeat: no-repeat;
    background-position: 87% 100%;
    padding: 100px 0 120px 0;
    @media (max-width: 1142px) {
      background-image: none;
    }
    @media (max-width: 600px) {
      padding: 50px 0;
    }
    .inner-wrap {
      .col {
        padding: 0;
        font-size: 14px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 300;
        flex-grow: 0;
        flex-basis: auto;
        width: 40%;
        color: #fff;
        @media (max-width: 1200px) {
          width: 60%;
        }
        @media (max-width: 900px) {
          width: 100%;
        }

        h4 {
          font-size: 28px;
          font-weight: 700;
          margin-bottom: 14px;
          font-family: $title-font-family;
        }
        p {
          font-size: 13px;
          font-weight: 400;
          color: #ffffff;
          font-family: $standard-font-family;
          margin-bottom: 35px;
        }

        @media (max-width: 460px) {
          .contactinfo {
            text-align: center;
            .btn_red {
              width: 100%;
              display: block;
            }
            span {
              width: 100%;
              display: block;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .middlebottom {
    background: $darkgrey;
    width: 100%;
    padding: 80px 0 60px 0;
    @media (max-width: 750px) {
      padding: 50px 0 20px;
    }
    .inner-wrap {
      .row {
        margin: 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: stretch;
        .col {
          padding: 0;
          flex-basis: auto;
          flex-grow: 0;
          color: #fff;
          &:nth-child(1) {
            width: 50%;
            margin-right: 120px;
            @media (max-width: 1010px) {
              margin-right: 50px;
            }
            p {
              font-size: 12px;
              line-height: 1.5;
              font-weight: 300;
            }
            img {
              margin-bottom: 20px;
              width: 50%;
            }
          }
          @media (max-width: 750px) {
            &:nth-child(1) {
              width: 100%;
            }
            &:nth-child(2) {
              display: none;
            }
          }
          ul {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;

            li {
              background: none !important;
              margin: 0;
              padding: 0;
              margin-bottom: 4px;
              margin-top: 0;
              width: 100%;
              a {
                color: #fff;
                font-size: 12px;
                font-weight: 300;
                font-family: $standard-font-family;
                width: 100%;
                &:before {
                  content: " ";
                  display: block;
                  border-radius: 10px;
                  background: $darkred;
                  width: 8px;
                  height: 8px;
                  display: inline-block;
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    background: $darkgrey;
    width: 100%;
    border-top: 1px solid #d8d8d8;
    padding: 30px 0;
    .inner-wrap {
      .row {
        margin: 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: space-between;
        @media (max-width: 750px) {
          flex-direction: column;
        }
        .col {
          padding: 0;
          flex-basis: auto;
          flex-grow: 0;
          color: #fff;
          width: 50%;

          &:nth-child(2) {
            text-align: right;
          }
          a {
            color: #fff;
            text-decoration: underline;
          }
          @media (max-width: 750px) {
            width: 100%;
            display: block;
            padding-bottom: 10px;
            &:nth-child(2) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
