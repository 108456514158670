.map {
  height:520px;
  margin:0;
  width:100%;
  display: block;
  @media(max-width:740px) {
    width:100%;
    height: 300px;
  }
  .infoMarker {
    text-align:left;
    color:#333;
  }
}
