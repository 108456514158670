.slick-list {
  width: calc(100% + 20px);
  margin: 0 -10px;
  .slick-slide {
    margin: 0 10px;
    @media (max-width: 900px) {
    }
  }
}

.controls {
  display: flex;
  flex-direction: row;
  margin: 0 0 2.5% 0;
  justify-content: flex-end;
  margin-right: 10px;
  @media (max-width: 900px) {
    display: none;
  }
  .gallerypagination {
    width: fit-content;
    ul.slick-dots {
      position: static;
      bottom: 0;
      line-height: 0;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        background: none;
        button {
          position: absolute;
          top: 6px;
          left: 6px;
          height: 1px;
          width: 1px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          background: #616161;
          margin: 0;
          padding: 4px;
          cursor: pointer;
          &:before {
            display: none;
          }
        }
        &.slick-active {
          button {
            background: $lightred;
          }
          border-radius: 50%;
          border: 1px solid #616161;
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }

  #prev-btn {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding-right: 35px;
    cursor: pointer;
    color: #616161;
  }
  #nxt-btn {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding-left: 20px;
    cursor: pointer;
    color: #616161;
  }
}
