.inner-wrap {
  max-width: $container;
  width: 90%;
  margin: auto;
}

.page-mid {
  padding: 60px 0;
  background: #fff;

  h1 {
    color: $darkred;
    font-size: 56px;

    @media (max-width: 900px) {
      font-size: 48px;
    }

    @media (max-width: 600px) {
      font-size: 42px;
    }

    @media (max-width: 450px) {
      font-size: 38px;
    }
  }

  h2 {
    font-size: 42px;
    color: $darkred;

    @media (max-width: 900px) {
      font-size: 37px;
    }

    @media (max-width: 600px) {
      font-size: 33px;
    }

    @media (max-width: 450px) {
      font-size: 30px;
    }
  }

  a.btn {
    margin-top: 10px;
  }

  &.alt {
    h1 {
      color: #fff;
    }
  }

  &.hr {
    border-top: 1px #d3d3d3 solid;
  }
}

.main {
  padding: 40px 0;

  h1 {
    margin-bottom: 40px;
  }
}

.description {
  width: 100%;
  display: flex;
  margin-bottom: 5%;
  margin-top: 5.5%;

  @media (max-width: 790px) {
    flex-direction: column;
  }

  .textarea {
    color: black;
    padding: 0% 15% 5% 0;
    flex-grow: 1;
    width: 65%;

    @media (max-width: 1200px) {
      width: 60%;
      padding: 5% 5% 5% 5%;
    }

    @media (max-width: 970px) {
      width: 50%;
      padding: 3% 3% 3% 3%;
    }

    @media (max-width: 790px) {
      width: 100%;
      order: 1;
      padding: 5% 5% 5% 5%;
    }

    h2 {
      font-size: 27px;
      font-weight: 700;
      color: $lightred;

      @media (max-width: 455px) {
        font-size: 22px;
        margin: 19px 0;
      }
    }

    p {
      font-size: 14px;
    }

    ul {
      li {
        width: 100%;
        display: block;
        clear: both;
        padding: 10px 0;
        font-weight: 400;

        &:before {
          content: " ";
          background-image: url("/assets/img/tick.svg");
          background-repeat: no-repeat;
          background-position: center;
          float: left;
          background-color: $yellow;
          border-radius: 20px;
          background-size: 13px;
          display: block;
          width: 25px;
          height: 25px;
          margin-right: 10px;
          color: #fff;
        }
      }
    }
  }

  .imagewrap {
    width: 35%;
    background-size: cover;

    img {
      opacity: 0;
    }

    @media (max-width: 1200px) {
      width: 40%;
    }

    @media (max-width: 970px) {
      width: 50%;
    }

    @media (max-width: 790px) {
      width: 100%;
      order: 2;

      img {
        opacity: 1;
      }
    }
  }
}

.page-btm {
  padding: 50px 0;
  background: #fff;

  .inner-wrap {
    h3 {
      font-family: $title-font-family;
      font-style: normal;
      font-weight: 900;
      text-transform: uppercase;
      color: $blue;
      font-size: 42px;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: #171717;
      line-height: 1.5;
      font-style: normal;
      font-weight: 300;
      max-width: 485px;
    }

    ul {
      display: flex;
      margin-top: 35px;
      margin-bottom: 15px;
      margin-left: -20px;
      margin-right: -20px;

      @media (max-width: 750px) {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
      }

      li {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(33.33% - 40px);
        background: #1f3b5e;

        @media (max-width: 750px) {
          width: calc(100%);
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 0;
          margin-right: 0;
          display: flex;
        }

        @media (max-width: 340px) {
          flex-direction: column;
        }

        .img-wrap {
          height: 0;
          overflow: hidden;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          padding-top: 55%;

          img {
            opacity: 0;
          }

          @media (max-width: 750px) {
            height: 100px;
            width: 33%;
            padding-top: 0;
          }

          @media (max-width: 340px) {
            height: auto;
            padding: 0;
            width: 100%;
          }
        }

        .txt-wrap {
          min-height: 75px;
          padding: 15px 30px;
          font-size: 18px;
          color: #ffffff;
          font-family: $title-font-family;
          font-style: normal;
          font-weight: 500;
          text-align: center;
          line-height: 1.3;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          @media (max-width: 750px) {
            width: 77%;
          }

          @media (max-width: 340px) {
            width: 100%;
          }
        }
      }
    }
  }

  &.alt {
    .inner-wrap {
      h3 {
        color: #fff;
      }

      p {
        color: #fff;
      }
    }
  }
}

.socialmedia {
  display: inline-block;
  margin: 0;

  a {
    border-radius: 50%;
    display: inline-block;
    width: 33px;
    height: 33px;
    padding-top: 8px;
    justify-content: center;
    margin-left: 8px;
    color: #fff;
    font-size: 18px;
    text-align: center;

    &.fa-facebook {
      background: #44659e;
    }

    &.fa-twitter {
      background: #00b2f0;
    }

    &.fa-instagram {
      background: #f09433;
      background: linear-gradient(45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%);
    }

    &.fa-youtube {
      background: #de381b;
    }

    &:nth-child(1) {
      margin-left: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.contactinfo {
  font-weight: 700;
  font-size: 14px;
  font-family: $title-font-family;

  .btn_red {
    font-size: 14px;
    font-weight: 700;
    font-family: $title-font-family;
    padding: 12px 22px;
    margin-right: 20px;

    &:after {
      content: " ";
      background-image: url("/assets/img/arrow_white.svg");
      background-repeat: no-repeat;
      background-size: 7px;
      background-position: center 3px;
      display: block;
      width: 9px;
      height: 14px;
      margin-left: 10px;
      color: #fff;
      display: inline-block;
    }
  }

  span {
    font-size: 18px;
    margin-left: 16px;
  }
}

.slider {
  width: 100%;
  margin: 0 auto;
}

.slick-slide {
  margin: 0px;
}

.carouselnavigation {
  display: inline-block;
  margin-top: 5px;

  .prev {
    background-image: url("/assets/img/slickprev.svg");
    background-repeat: no-repeat;
    background-size: 9px;
    background-position: center 3px;
    background-color: #fff;
    width: 14px;
    height: 17px;
    float: right;
    margin-right: 5px;
    margin-top: 0px;

    &:focus {
      outline: none;
    }
  }

  .next {
    background-image: url("/assets/img/slicknext.svg");
    background-repeat: no-repeat;
    background-size: 9px;
    background-position: center 3px;
    background-color: #fff;
    width: 14px;
    height: 17px;
    float: right;
    margin-left: 5px;
    margin-top: 0px;

    &:focus {
      outline: none;
    }
  }
}

.slickpagination {
  float: right;
  height: 17px;
}

.dots {
  float: left;
  padding: 4.4px 0;

  @media (max-width: 630px) {
    display: none !important;
  }

  li {
    width: 10px;
    height: 10px;
    background: $darkred;
    border-radius: 50%;
    text-indent: -9999px;
    display: inline-block;
    margin: 0 2px;
    cursor: pointer;

    &.slick-active {
      background: $yellow;
    }
  }
}

.boxedcontent {
  background-color: #f6f6f6;
  width: 35%;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 970px) {
    width: 50%;
  }

  @media (max-width: 790px) {
    width: 100%;
    order: 2;
  }

  .txt-wrap {
    padding: 10%;

    h4 {
      font-size: 20px;
      color: #b62329;
      font-weight: bold;
    }

    ul {
      list-style: disc;
      margin: 5% 0 0 5%;

      li {
        padding: 5px 0;
      }
    }
  }
}