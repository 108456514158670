$title-font-family: "proxima-nova", sans-serif;
$standard-font-family: "open-sans", sans-serif;

$container: 1200px;

$blue: "blue";
$red: "red";
$yellow: #ffda3c;
$darkred: #9c0006;
$lightred: #b62329;
$grey: #404040;
$darkgrey: #2a2a2a;
$offwhite: #f6f7fa;
$textshadow: 0px 0px 4px rgba(0, 0, 0, 0.67);

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;

$imgpath: "../../../../assets/img/";
