.slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 0 !important;

  .detail {
    padding-top: 32.4%;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
    }

    .textarea {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(90deg, #000000 0%, rgba(28, 73, 115, 0) 100%);

      @media (max-width: 550px) {
        background: $darkred;
        position: relative;
        height: 250px;
        top: 12px;
      }

      .inner-wrap {
        padding: 9% 0;

        @media (max-width: 1038px) {
          padding: 8% 0;
        }

        @media (max-width: 820px) {
          padding: 6%;
        }
      }

      h2 {
        font-family: $title-font-family;
        font-size: 47px;
        font-weight: 800;
        text-transform: uppercase;
        color: #ffffff;
        padding: 0 50% 0 0;

        @media (max-width: 1038px) {
          padding: 0 40% 0 0;
          font-size: 40px;
        }

        @media (max-width: 820px) {
          padding: 0;
          margin-bottom: 2%;
        }

        @media (max-width: 736px) {
          font-size: 30px;
        }

        @media (max-width: 550px) {
          font-size: 26px;
        }
      }

      p {
        font-family: $standard-font-family;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        padding: 0 55% 0 0;

        @media (max-width: 1038px) {
          padding: 0 40% 0 0;
        }

        @media (max-width: 800px) {
          padding: 0;
          margin-bottom: 2%;
        }

        @media (max-width: 550px) {
          font-size: 12px;
        }
      }

      a {
        font-family: $title-font-family;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;

        &:after {
          content: " ";
          display: block;
          width: 7px;
          height: 11px;
          margin-left: 10px;
          color: #fff;
          display: inline-block;
        }
      }
    }
  }
}

.paginationoutter {
  width: 100%;
  height: 50px;
  background: $lightred;
  position: relative;
  margin-bottom: 1%;

  @media (max-width: 800px) {
    height: 10px;
  }

  @media (max-width: 550px) {
    top: -4px;
    margin-bottom: 10%;
  }
}

.slideshow-pagination {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 50px;

  @media (max-width: 800px) {
    height: 10px;
    top: -12px;
  }

  ul {
    text-align: left;
    position: relative;
    float: left;
    bottom: 0;

    @media (max-width: 800px) {
      height: 10px;
    }

    li {
      display: inline-block;
      width: 25%;
      height: 100%;
      background: $darkred;
      margin: 0;
      border-right: 1px solid $yellow;
      color: #ffffff;

      @media (max-width: 800px) {
        height: 10px;
      }

      @media (max-width: 550px) {
        background: $lightred;
        border-right: 1px solid #ffffff;
      }

      div {
        font-family: $title-font-family;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;

        @media (max-width: 800px) {
          display: none;
        }
      }

      &.slick-active {
        background: $yellow;
        color: $darkred;

        &:after {
          width: 0;
          height: 0;
          content: "";
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 15px solid $yellow;
          position: absolute;
          top: -11px;
          left: 46%;

          @media (max-width: 820px) {
            display: none;
          }
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}