.product {
  background: #ffffff;
  padding: 50px 0 70px 0;
  font-family: $standard-font-family;
  color: #7d7d7d;
  @media (max-width: 700px) {
    padding: 30px 0 50px 0;
  }
  .inner-wrap {
    .row {
      margin: 0;
      @media (max-width: 700px) {
        display: block;
      }
      .col {
        padding-left: 0;
        padding-right: 3%;
        @media (max-width: 700px) {
          padding-right: 0;
        }
        &:nth-child(1) {
          @media (min-width: 700px) {
            .category {
              display: none;
            }
            h1 {
              display: none;
            }
          }
          @media (max-width: 700px) {
            .category {
              display: block;
            }
            h1 {
              display: block;
            }
          }
        }
        &:nth-child(2) {
          padding-left: 3%;
          padding-right: 0;
          padding-top: 2%;
          @media (max-width: 700px) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            .category {
              display: none;
            }
            h1 {
              display: none;
            }
          }
        }
        p {
          font-style: 14px;
          color: #1d1d1d;
          @media (max-width: 965px) {
            display: none;
          }
        }
        .moreinfobtn {
          @media (max-width: 965px) {
            display: none;
          }
        }
        .category {
          a {
            border-left: 2px solid #848694;
            padding: 0 10px;
            h3 {
              font-size: 16px;
              font-weight: 700;
              color: #848694;
              display: inline;
            }
            &:first-child {
              border-left: none;
              padding: 0 10px 0 0;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
        h1 {
          font-size: 27px;
          margin-top: 7px;
        }
        .prices {
          background: #ffffff;
          border: 1px solid #e1e1e1;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 10px;
          .col {
            border-right: 1px solid #e1e1e1;
            padding: 3% 5%;
            @media (max-width: 700px) {
              width: 33.3%;
              display: inline-block;
            }
            @media (max-width: 340px) {
              width: 100%;
              display: block;
              border-right: none;
              border-bottom: 1px solid #e1e1e1;
            }

            h5 {
              font-size: 20px;
              color: $lightred;
              font-family: $title-font-family;
              font-weight: 700;
              margin-bottom: 0;
              @media (max-width: 1107px) {
                font-size: 16px;
              }
              @media (max-width: 845px) {
                font-size: 14px;
              }
              @media (max-width: 700px) {
                font-size: 20px;
              }
              @media (max-width: 537px) {
                font-size: 16px;
              }
              @media (max-width: 400px) {
                font-size: 14px;
              }
            }
            h6 {
              font-size: 16px;
              color: #848694;
              font-family: $title-font-family;
              font-weight: 700;
              margin-bottom: 0;
              @media (max-width: 1107px) {
                font-size: 12px;
              }
              @media (max-width: 845px) {
                font-size: 10px;
              }
              @media (max-width: 700px) {
                font-size: 16px;
              }
              @media (max-width: 537px) {
                font-size: 12px;
              }
              @media (max-width: 400px) {
                font-size: 10px;
              }
            }
            &:last-child {
              border-right: none;
            }
          }
        }
        .contactinfo {
          margin-bottom: 30px;
          color: $darkred;
          .btn_red {
            font-size: 18px;
          }
          span {
            color: $darkred;
          }
          @media (max-width: 965px) {
            text-align: center;
            .btn_red {
              width: 100%;
              display: block;
            }
            span {
              width: 100%;
              display: block;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
.product-bottom {
  background: #ffffff;
  border-top: 2px solid #d8d8d8;
  font-family: $standard-font-family;
  color: #7d7d7d;
  .inner-wrap {
    padding: 40px 0;

    .row {
      margin: 0;
      @media (max-width: 900px) {
        display: block;
      }
      .col {
        padding-left: 0;
        padding-right: 3%;
        @media (max-width: 900px) {
          width: 100%;
          display: block;
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-left: 3%;
          padding-right: 0;
          @media (max-width: 900px) {
            width: 100%;
            display: block;
            padding-left: 0;
            margin-top: 40px;
          }
          h3 {
            color: #7d7d7d;
            font-size: 17px;
            font-weight: 700;
            font-family: $title-font-family;
            margin-bottom: 19px;
            margin-top: 13px;
          }
        }
        p {
          font-style: 15px;
          color: #1d1d1d;
        }
      }
    }
  }
}

/* Tabs container */
.r-tabs {
  position: relative;
  ul {
    border-bottom: 2px solid #d8d8d8;
    .inner-wrap {
      padding: 0;
    }
    .r-tabs-tab {
      border-right: 2px solid #d8d8d8;
      &:first-child {
        border-left: 2px solid #d8d8d8;
      }
      &.r-tabs-state-active {
        a {
          border-bottom: 7px solid $lightred;
          padding: 30px 30px 23px 30px;
        }
      }
      a {
        color: #334958;
        font-size: 17px;
        font-weight: 600;
        font-family: $title-font-family;
        padding: 30px 30px;
        display: block;
      }
    }
  }
}

/* Disabled tab */
.r-tabs .r-tabs-nav .r-tabs-state-disabled {
  opacity: 0.5;
}

/* Tab panel */
.r-tabs .r-tabs-panel {
  background-color: white;
  padding: 0 0 0 0;
}

/* Accordion anchor */
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
  display: block;
  padding: 20px 5%;
  color: #fff;
  border-bottom: 1px solid;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  background-color: $darkred;
  &:after {
    content: " ";
    background-image: url("/assets/img/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    background-color: #ffda3c;
    border-radius: 20px;
    background-size: 7px;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: #fff;
    display: inline-block;
  }
}

/* Active accordion anchor */
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
  background-color: $darkred;
  text-shadow: none;
}

/* Disabled accordion button */
.r-tabs .r-tabs-accordion-title.r-tabs-state-disabled {
}

.vatprice {
  font-size: 12px;
  color: #333;
  margin-bottom: 30px;
  @media (max-width: 965px) {
    display: block !important;
    width: 100%;
    text-align: center;
  }
}
